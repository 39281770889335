const Chat = {
    state: {
        newMsg: {},
        lawyerThirdId:'',
        noreadtotal:0,
        showTip:true
    },
    mutations: {
        setNewMsg(state, info) {
            state.newMsg = info;
        },
        setLawyerThirdId(state, info) {
            state.lawyerThirdId = info;
        },
        setnoreadtotal(state, info) {
            state.noreadtotal = info;
        },
        setshowTip(state, info) {
            state.showTip = info;
        },
    },
    actions: {
        onNewMsg: function (context, payload) {
            context.commit("setNewMsg", payload);
        },
        onLawyerThirdId: function (context, payload) {
            context.commit("setLawyerThirdId", payload);
        },
        onnoreadtotal: function (context, payload) {
            context.commit("setnoreadtotal", payload);
        },
        onshowTip: function (context, payload) {
            context.commit("setshowTip", payload);
        },
    },
    getters: {
        onNewMsg(state) {
            return state.newMsg
        },
        onLawyerThirdId(state) {
            return state.lawyerThirdId
        },
        onnoreadtotal(state) {
            return state.noreadtotal
        },
        onshowTip(state) {
            return state.showTip
        },
    }

};
export default Chat;