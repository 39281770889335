import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/index'
  }, {
    path: '/index',
    name: 'index',
    component: ()=>import('../views/index.vue')
  },
  {
    path: '/ask', //找律师
    name: 'ask',
    component: ()=>import('../views/ask.vue')
  },
  {
    path: '/login', //登录
    name: 'login',
    component: ()=>import('../views/login.vue')
  },
  {
    path: '/recommend', //推荐律师
    name: 'recommend',
     component: ()=>import('../views/recommend.vue')
  },
  {
    path: '/lawyer', //律师详情
    name: 'lawyer',
    component: ()=>import('../views/lawyer.vue')
  },
  {
    path: '/commentList', //全部评价
    name: 'commentList',
    component: ()=>import('../views/commentList.vue')
  },
  {
    path: '/contract', //合同模板
    name: 'contract',
    component: ()=>import('../views/contract.vue')
  },
  {
    path: '/contractDetail', //合同模板详情
    name: 'contractDetail',
     component: ()=>import('../views/contractDetail.vue')
  },
  {
    path: '/member', //会员
    name: 'member',
    component: ()=>import('../views/member.vue')
  },
  {
    path: '/center', //个人中心
    name: 'center',
    component: ()=>import('../views/center.vue')

  },
  {
    path: '/cashier', //支付
    name: 'cashier',
    component: ()=>import('../views/cashier.vue')
  },
  {
    path: '/download', //客户端下载
    name: 'download',
     component: ()=>import('../views/download.vue')
  },
  {
    path: '/pingan', //平安保险客户端下载
    name: 'pingan',
     component: ()=>import('../views/pingan.vue')
  },
  {
    path: '/agreement', //用户协议
    name: 'agreement',
     component: ()=>import('../views/agreement.vue')
  },
  {
    path: '/privacy', //隐私协议
    name: 'privacy',
     component: ()=>import('../views/privacy.vue')
  },
  {
    path: '/chat', //消息列表
    name: 'chat',
     component: ()=>import('../views/chat.vue')
  },
  {
    path: '/wait', //倒计时
    name: 'wait',
     component: ()=>import('../views/wait.vue')
  },
  {
    path: '/chatroom', //聊天窗
    name: 'chatroom',
     component: ()=>import('../views/chatroom.vue')
  },
  {
    path: '/commentOrder', //评价订单
    name: 'commentOrder',
     component: ()=>import('../views/commentOrder.vue')
  },
  {
    path: '/comment', //评价
    name: 'comment',
    component: ()=>import('../views/comment.vue')
  },
  {
    path: '/selectCoupon', //选择优惠券
    name: 'selectCoupon',
    component: ()=>import('../views/selectCoupon.vue')
  },
  {
    path: '/personInfo',
    name: 'personInfo',
     component: ()=>import('../views/personInfo.vue')
  },
  {
    path: '/coupon',
    name: 'coupon',
     component: ()=>import('../views/coupon.vue')
  },
  {
    path: "/mouldboard",
    name: '/mouldboard',
     component: ()=>import('../views/mouldboard.vue')
  },
  {
    path: '/about',
    name: '/about',
    component: ()=>import('../views/about.vue')
  },
  {
    path: '/rule',
    name: '/rule',
    component: ()=>import('../views/rule.vue')
  },
  {
    path: '/record',
    name: '/record',
    component: ()=>import('../views/record.vue')
  },
  {
    path: '/order',
    name: '/order',
    component: ()=>import('../views/order.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: ()=>import('../views/case.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: ()=>import('../views/pay.vue')
  },
  {
    path: '/pay_cashier',
    name: 'pay_cashier',
    component: ()=>import('../views/pay_cashier.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: ()=>import('../views/payment.vue')
  },
  {
    path: '/pay_result',
    name: 'pay_result',
    component: ()=>import('../views/pay_result.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
