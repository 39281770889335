import Vue from 'vue'
import router from '../router'
import store from '@/store';
import config from "./WebIMConfig";
import websdk from "easemob-websdk";
import _ from 'lodash'
import {

} from "@/api";

import { Toast,Dialog } from 'vant';
var remoteUsers = {};

var client;
// 初始化IM SDK
var WebIM = {};
WebIM = window.WebIM = websdk;
WebIM.config = config;
WebIM.conn = new WebIM.connection({
  appKey: WebIM.config.appkey,
  isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
  https: WebIM.config.https,
  isAutoLogin: true,
  heartBeatWait: 30000,
  autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
  autoReconnectInterval: WebIM.config.autoReconnectInterval,
  isStropheLog: WebIM.config.isStropheLog,
  delivery: WebIM.config.delivery,
  useOwnUploadFun: WebIM.config.useOwnUploadFun,
  //公有云 isHttpDNS 默认配置为true
  isHttpDNS: WebIM.config.isHttpDNS,

  // 私有云设置，详细文档：http://docs-im.easemob.com/im/web/other/privatedeploy
  // isHttpDNS: false,
  // url: 'https://im-api-v2.easecdn.com/ws', // 设置为私有云的websocket server url
  // apiUrl: 'https://a1.easecdn.com', // 设置为私有云的rest server url
});
//通话状态
// const CALLSTATUS = {
// 	idle: 0,
// 	inviting: 1,
// 	alerting: 2,
// 	confirmRing: 3, // caller
// 	receivedConfirmRing: 4, // callee
// 	answerCall: 5,
// 	receivedAnswerCall: 6,
// 	confirmCallee: 7
// }
Vue.prototype.loginIM = () => {
  var user = JSON.parse(localStorage.getItem('user'));
  if(localStorage.getItem('user')){
    var options = {
      user: user.MemberThirdId,
      pwd: '000000',
      appKey: WebIM.config.appkey,
      apiUrl: 'https://a1.easecdn.com'
    };
    WebIM.conn.open(options);
  }
}
var errorNum = 0,
  that = this
// console.log(router.history.current.path)
document.addEventListener('visibilitychange', () => {
  console.log(document.hidden)
  if (document.hidden) {
    // WebIM.conn.close()
  } else {
    if (router.history.current.path != '/login') {
      setTimeout(() => {
        Vue.prototype.loginIM()
        errorNum = 0
      }, 1000)
    }
  }
})
// 注册监听回调
WebIM.conn.listen({
  onOpened: function () { // 连接成功回调
    // 登录或注册成功后 跳转到好友页面
    console.log('连接成功')
    errorNum = 0
  },
  onClosed: function () {
    console.log('连接关闭')
    // router.push({name: 'Login'})
  }, // 连接关闭回调
  onTextMessage: function (message) {
    console.log('onTextMessage', message);
    saveMsg(message, 'text')
  }, // 收到文本消息
  onEmojiMessage: function (message) {
    console.log("onEmojiMessage", message);
    saveMsg(message, 'text')

  }, // 收到表情消息
  onPictureMessage: function (message) {
    console.log("onPictureMessage", message);
    saveMsg(message, 'picture')
  }, // 收到图片消息
  onCmdMessage: function (msg) {
    console.log('onCmdMessage', msg)

  },
  // 收到命令消息
  onAudioMessage: function (message) {
    console.log("onAudioMessage", message);
    saveMsg(message, 'audio')
  }, // 收到音频消息
  onLocationMessage: function (message) {
    console.log("onLocationMessage", message);
  }, // 收到位置消息
  onFileMessage: function (message) {
    console.log("onFileMessage", message);
    saveMsg(message, 'file')
  }, // 收到文件消息
  onVideoMessage: function (message) {
    console.log("onVideoMessage", message);

  }, // 收到视频消息
  onPresence: function (message) {
    console.log("onPresence", message);

	}, // 处理“广播”或“发布-订阅”消息，如联系人订阅请求、处理群组、聊天室被踢解散等消息
	onRoster: function (message) {
		console.log("onRoster", message);
	}, // 处理好友申请
	onInviteMessage: function (message) {
		console.log("onInviteMessage", message);
	}, // 处理群组邀请
	onOnline: function () {
		console.log("onOnline 网络已连接");
	}, // 本机网络连接成功
	onOffline: function () {
		console.log("onOffline 网络已断开");
		Toast({
			message: '网络已断开',
			duration: 1000,
		});
	}, // 本机网络掉线
	onError: function (message) {
		console.log('onError', message)
		var word = ''
		if (message.type == '206') {
			errorNum = 0
			Toast({
				message: '账号已在其他设备登录',
				duration: 1000,
			});
      console.log('账号已在其他设备登录')
			router.push('/login?to=index')
      // localStorage.removeItem('user')
		} else {
			if (errorNum < 30) {
				errorNum++
				setTimeout(function () {
					Vue.prototype.loginIM()
				}, 1000)
			} else {
				Dialog.confirm({
					title:'提示',
					message:'连接已断开，请重连',
					confirmButtonText: '确定',
					showCancelButton: false,
				}).then(() => {
					setTimeout(function () {
						Vue.prototype.loginIM()
						errorNum = 0
					}, 1000)
				}).catch(() => {});
			}

      }
    }, // 失败回调
  onRecallMessage: message => {
    console.log("撤回消息", message);
    var msg = localStorage.getItem(message.to + message.from) ? JSON.parse(localStorage.getItem(message.to + message.from)) : [];
    var chatList = localStorage.getItem(message.from + 'chatList') ? JSON.parse(localStorage.getItem(message.from + 'chatList')) : []
    var withTime = '';
    for (let i = 0; i < msg.length; i++) {
      if (msg[i].id === message.mid) {
        msg[i].msgType = "widthdraw"
        msg[i].data = "你撤回了一条消息"
        msg[i].sourceMsg = "你撤回了一条消息"
        withTime = getYMDHMS(parseInt(msg[i].time))
      }
    }
    localStorage.setItem(message.to + message.from, JSON.stringify(msg))
    for (let j = 0; j < chatList.length; j++) {
      if (chatList[j].memberThirdId_kh === message.to && chatList[j].id === message.mid) {
        chatList[j].lastWord = '你撤回了一条消息'
      }
    }
    localStorage.setItem(message.from + 'chatList', JSON.stringify(chatList))
    var data = {
      contentsType: "TEXT",
      data: '你撤回了一条消息',
      error: false,
      errorCode: 0,
      errorText: "",
      ext: {},
      from: message.from,
      id: message.mid,
      msgConfig: null,
      msgType: "widthdraw",
      sourceMsg: '你撤回了一条消息',
      time: withTime,
      to: message.to,
      type: "chat"
    }
    store.dispatch('onNewMsg', data)
    store.dispatch("onUpdateMessageStatus", message);
  },
  onBlacklistUpdate: function (list) { // 黑名单变动
    // 查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息
    // 更新好友黑名单
    console.log("黑名单变动", list);
    // let blackList = list;
    // Vue.$store.commit("updateBlackList", blackList);
  },
  onReceivedMessage: function (message) {
    // console.log("onReceivedMessage", message);
    // Vue.$store.commit("updateMessageMid", message);
    // message.status = "sent";
    // Vue.$store.commit("updateMessageStatus", message);
  }, // 收到消息送达服务器回执

  onDeliveredMessage: function (message) {
    // console.log("onDeliveredMessage", message);
    // Vue.$store.commit('updateMessageStatus', message)
  }, // 收到消息送达客户端回执

  onReadMessage: function (message) {
    console.log("onReadMessage", message);
    // message.status = "read";
    // Vue.$store.commit("updateMessageStatus", message);
  }, // 收到消息已读回执

  onCreateGroup: function (message) {
    console.log("onCreateGroup", message);
    // Vue.$store.dispatch("onGetGroupUserList");
  }, // 创建群组成功回执（需调用createGroupNew）
  onMutedMessage: function (message) {
    console.log("onMutedMessage", message);
  } // 如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
});


function saveMsg(message, type) {
  var user = JSON.parse(localStorage.getItem('user'));
  var userThirdId, chatMsg, chatList, lawyerThirdId
  message.msgType = type
  console.log('saveMsg-------'+message.ext.ShowType)
  console.log(user)
  if (message.to == user.MemberThirdId || message.from == user.MemberThirdId) {
    console.log(message.to)
    if (message.to == user.MemberThirdId) {
      lawyerThirdId = message.from;
      userThirdId = message.to
    } else {
      lawyerThirdId = message.to;
      userThirdId = message.from
    }
    chatMsg = localStorage.getItem(userThirdId + lawyerThirdId) ? JSON.parse(localStorage.getItem(userThirdId + lawyerThirdId)) : []
    console.log(chatMsg)
    chatMsg.push(message)
    localStorage.setItem(userThirdId + lawyerThirdId, JSON.stringify(chatMsg))
    if (message.to == user.MemberThirdId) {
      var noreadnum = localStorage.getItem(userThirdId + lawyerThirdId + '_noreadnum') ? localStorage.getItem(userThirdId + lawyerThirdId + '_noreadnum') + '_noreadnum' : 0
      localStorage.setItem(userThirdId + lawyerThirdId + '_noreadnum', parseInt(noreadnum) + 1)
      var noreadtotal=localStorage.getItem(user.MemberThirdId+'_noreadtotal') ? localStorage.getItem(user.MemberThirdId+'_noreadtotal'): 0
      localStorage.setItem(user.MemberThirdId+'_noreadtotal',parseInt(noreadtotal) + 1)
      store.dispatch('onnoreadtotal', parseInt(noreadtotal) + 1)
    }
    store.dispatch('onNewMsg', message)
    if (message.ext.ShowType == 'QiangDan') {
      console.log('QiangDan-------'+message.from)
      // store.dispatch('onLawyerThirdId', message.from)
      // localStorage.setItem('chatroomLawyer', message.from)
      setTimeout(()=>{
      router.replace('/chatroom?lawyerThirdId='+message.from)
      },500)

    }
  }
}

function getYMDHMS(timestamp) {
  let time = new Date(timestamp)
  // let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()
  let hours = time.getHours()
  let minute = time.getMinutes()
  // let second = time.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minute < 10) {
    minute = '0' + minute
  }
  // if (second < 10) { second = '0' + second }
  // return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
  return month + '月' + date + '日 ' + hours + ':' + minute
}

export default WebIM;
