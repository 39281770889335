<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  import WebIM from "./utils/WebIM";
  import {SingleLogin} from '@/api/index'
  export default {
    mounted() {
      console.log(this.$route)
      
      if (this.getUrlParamCN('type')) {
        localStorage.setItem('orderfrom', this.getUrlParamCN('type'))
      } else {
        localStorage.setItem('orderfrom', 'mob')
      }
      if (localStorage.getItem('user')) {
        this.loginIM()
      }
      var value=localStorage.getItem('orderfrom')
      //和生活单点登录
        if(value=='hbfsd'){
            var val=this.getUrlParamCN("value");
            localStorage.setItem('orderfrom', 'MOB-hbfsd')
            var key ="MIICXgIBAAKBgQDXAIL8Yp0qDS+jYAsvwY1LDfve5gVGakxvsCW6GJGogLK8qyxHZfWTexGVWLiMHXuQFT7j95pmPAANhFsYkkEmSruIvdgy/I5E6V4+2pt1t9w/aj49XoAuzuqZ4furHqldgSiSOf2ePfrZEgEC16VQhuwIem9N5+nVkfKBVtxixQIDAQABAoGBANBBzZ8dAnFuLlvJN2y9xdgSpotlMWzgoEN7Uz/sdoLKjtdT0uMh7vs5NXH9a11FXZ/WBIIbH7Kxobd2Iist1+2x4sXYplVhNVqKEagjHStU1aI1jzjgZQAbrbpJpnaq6qd8y5FAWGrIYVpqvqL2rYO7dOjRoXKidS0S1+wob1zhAkEA7YTB0Vei5dqINtNs8tBeW8XVsknf2gcOQEcyvEbO/tMmajGsfdUI3bI+Gm9qzZYWjMZ59grqD+lRvw5FBfE4SQJBAOe7PHzNvZdtUrZXHJ7IttU65pMFdUqzfpbI9rGgSrMU4LMHogfBxOwoSLc16mXdthd8bp7OJo4cj2v5E5zR7p0CQQCv20pRgAxZGMsfpLyWrxYd5sVI4w99cxnnF5AF/HtICvf3Sl4HMJMG6fC3S+UM4GJDyrQEKqKQM1TWG2+ZbbcZAkAhwAJiRvDLAjAewHc5pRAD5E06snvPwm64dVKAslDO3kaPrJfru0v7/S4CCQc3iOABca0G0g2Jex1kY2V43JZ9AkEApkk0fzg4xV/jVLqmBGWDXZ0C2vCL5nmW/as4ojGqS1U3UViIVO8Zl0xbiQGw7kS237SgWw62Hn1SsOlVfQR7IA=="
            var Encrypt = new JSEncrypt();
            Encrypt.setPrivateKey(key);
            var hdd=Encrypt.decrypt(decodeURIComponent(val))
            console.log(hdd)
            var postData={
                "MemberMoblie": hdd,
                "MemberComeFrom": 'MOB-hbfsd'
            }
            SingleLogin(postData).then(res=>{
                 localStorage.setItem('user', JSON.stringify(res.data.UserInfo))
            })
        }
         //苏解纷登录
        // if(value=='sjf-miniapp'){
            
        // }else{

        // }
        if(this.getUrlParamCN("mobile")){
        var mobile=this.getUrlParamCN("mobile");
          var postData={
              "MemberMoblie": mobile,
              "Name": "",
              "Sex": "",
              "MemberComeFrom": value
          }
          SingleLogin(postData).then(res=>{
                localStorage.setItem('user', JSON.stringify(res.data.UserInfo))
          })
        }


    },
    methods:{
      getUrlParamCN(name) {
          var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
          var r = decodeURI(window.location.search).substr(1).match(reg); //匹配目标参数
          if (r != null) return unescape(r[2]); return null; //返回参数值
      }
    },
    watch: {
      '$route'(to, from) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
    }
  }

</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    max-width: 750px;
    min-width: 240px;
  }

  * {
    padding: 0;
    margin: 0;
  }

  input:focus {
    outline: 0;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #aaa;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaa;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaa;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #aaa;
  }
  // 轻提示
  .van-toast {
    font-size: 28px !important;
    line-height: 32px !important;
    padding: 15px 30px !important;
  }

  .van-picker__toolbar {
    padding-top: 20px;
  }
  // 确认弹窗
  .confirmDialog{
    width: 540px!important;
    
    .van-dialog__content{
      padding: 70px;
      text-align: center;font-size: 30px;color: #333333;
    }
    .van-button{
      height: 89px;font-size: 32px;color: #333333;
    }
  }
  .van-loading__spinner{
    width: 60px!important;
    height: 60px!important;
  }
</style>
